<div class="startAutoWrapper">
	<button type="button" class="start-button" *ngIf="!isRecording && !blobUrl" (click)="startRecording()">Start Recording</button>

	<button type="button" class="stop-button" *ngIf="isRecording && !blobUrl" (click)="stopRecording()">Stop Recording</button>

	<button type="button" class="cancel-button" *ngIf="!isRecording && blobUrl" (click)="clearRecordedData()">Clear Recording</button>

	<br>
	<button type="button" class="cancel-button" *ngIf="showDownloadBtn && !isRecording && blobUrl" (click)="download()">Download
		Recording</button>

	<div *ngIf="isRecording && !blobUrl"> {{recordedTime}} </div>
	<div>
		<audio *ngIf="!isRecording && blobUrl" controls>
			<source [src]="blobUrl" type="audio/webm">
		</audio>
	</div>
</div>